/* TODO: --wrapper-height is missing */

.container {
  margin-top: 76px;
  margin-bottom: var(--spacing-bottom);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);

  @media (width >= 650px) {
    margin-top: calc(var(--spacing-top) + var(--navbar-height));
  }

  @media (width >= 1000px) {
    margin-top: var(--spacing-bottom);
  }

  @media (width >= 1000px) {
    --_top-margin: calc(
      var(--spacing-bottom) + var(--navbar-height)
    ); /* used to offset can artwork */

    margin-top: var(--_top-margin);
  }
}

/* this is a magic number, visually adjusted to make the space between this and the next element smaller */
[data-first-block="true"] > .container {
  margin-bottom: calc(40px - var(--spacing-bottom));

  @media (width >= 650px) {
    margin-bottom: calc(80px - var(--spacing-bottom));
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.wrapper {
  position: relative;
  width: 100%;
  text-align: center;

  @media (width >= 1000px) {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
    margin-inline: auto;
    background-size: 100% 55%;
    text-align: initial;
  }
}

/* TODO: High in sugar mark for mobile doesn't fit in correct place */
.dietary-mark-high-sugar {
  --high-sugar-mark-inset-block-start: 58px;
  --high-sugar-mark-inset-inline-end: 20px;
  --high-sugar-mark-width: 45px;

  @media (width >= 650px) {
    --high-sugar-mark-inset-block-start: 100px;
    --high-sugar-mark-width: 55px;
  }
}

.artwork-wrapper {
  position: relative;
  z-index: 10;
  margin-inline: auto;
  margin-top: 44px; /* magic number for spacing between text and can */
  width: 105px;

  @media (width >= 650px) {
    width: 210px;
  }

  @media (width >= 1000px) {
    position: absolute;
    top: 0;
    grid-row: 1;
    grid-column: 2 / 4;
    margin-top: initial;
    margin-inline: initial;
    width: 152px;
    height: calc(
      var(--wrapper-height) - var(--navbar-height) - var(--_top-margin)
    );
  }
}

.artwork-wrapper--stamp {
  width: 160px;

  @media (width >= 650px) {
    width: 280px;
  }

  @media (width >= 1000px) {
    width: 152px;
  }
}

@media (width >= 1000px) {
  .artwork--sticky {
    --_offset: 78px; /* magic number to position when the can "sticks" */

    position: sticky;
    top: calc(var(--_top-margin) + var(--navbar-height) - var(--_offset));
  }
}

.artwork {
  display: block;
  position: relative;
  width: 100%;

  @media (width >= 1000px) {
    margin-top: -18px;
    width: 210px;
  }
}

@media (width >= 1000px) {
  .artwork--stamp {
    left: -125px;
    width: 380px;
  }
}

.dietary-mark-vegetarian {
  --vegetarian-mark-inset-block-end: 3px;
  --vegetarian-mark-inset-inline-end: calc(
    0px - calc(var(--vegetarian-mark-width) + 11px)
  );
  --vegetarian-mark-width: 16px;

  @media (width >= 650px) {
    --vegetarian-mark-width: 24px;
    --vegetarian-mark-inset-inline-end: calc(
      0px - calc(var(--vegetarian-mark-width) + 18px)
    );
  }
}

.pulltab {
  display: none;
  position: absolute;
  top: -51px;
  left: 50%;
  transform: translateX(-50%) rotateX(-80deg);
  transform-origin: bottom;
  perspective: 300px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: transform 0.3s ease-in-out;
  margin-left: -4px; /* visually centred, 3.75px matches the render */
  width: 82px;

  @media (width >= 1000px) {
    display: block;
    visibility: hidden;
  }
}

@media (width >= 1000px) {
  .pulltab[data-opened="true"] {
    transform: translateX(-50%) rotateX(0deg);
    visibility: visible;
  }
}

.artwork-img-wrapper {
  position: relative;
}

.artwork-img {
  display: block; /* TODO: top stop inline bottom padding, This should be a global rule */
  position: relative;
  width: 100%;
}

.content-wrapper {
  @media (width >= 650px) {
    display: flex;
    grid-column: 4 / -1;
    justify-content: center;
  }

  @media (width >= 1000px) {
    grid-column: 7 / -1;
  }
}

.kicker {
  margin-bottom: 36px;

  @media (width >= 650px) {
    margin-bottom: 44px;
  }
}

[data-has-custom-theme] .kicker {
  --kicker-color: var(--page-theme-contrast-color, unset);
  --kicker-border-color: var(--theming-kicker-border-color);
}

.title {
  display: block;
  margin-bottom: 40px;
  color: var(--page-theme-contrast-color, var(--color-text-heading));
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-40px);
  line-height: 1.14;
  font-family: var(--font-family-heading);

  @media (width >= 650px) {
    font-size: var(--font-size-56px);
    line-height: 1.1;
  }
}

.description {
  --prose-font-size: var(--font-size-18px);
  --prose-color-body: var(--page-theme-contrast-color, var(--color-text));
  --prose-line-height: 1.33;

  margin-bottom: 36px;

  @media (width >= 650px) {
    --prose-font-size: var(--font-size-24px);
    --prose-line-height: 1.5;

    margin-bottom: 0;
  }
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vw;
  font-size: var(--font-size-24px);
}
