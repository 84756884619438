.carousel {
  display: flex;
  flex-direction: column;
}

.embla {
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 50%;

  position: relative; /* for buttons */
  margin: auto;
  max-width: 100%;
}

.embla__viewport {
  overflow: visible; /* not needed, but set as a reminder for this carousel */
}

.embla__container {
  display: flex;
  backface-visibility: hidden;
  margin-left: calc(var(--slide-spacing) * -1);
  touch-action: pan-y pinch-zoom;
}

.embla__slide {
  flex: 0 0 90%;
  padding-left: var(--slide-spacing);
  min-width: 0;

  @media (width >= 650px) {
    flex: 0 0 var(--slide-size);
  }
}

.footer {
  margin-top: 36px;

  @media (width >= 650px) {
    margin-top: 44px;
  }
}

.progress {
  background-color: var(--color-surface-glass-dark-10);
  height: 1px;
}

.progress__bar {
  transition: max-width 0.3s linear;
  background-color: var(--color-surface-solid-dark-lighter);
  width: 100%;
  max-width: calc(var(--_index, 1) * calc(100 / var(--_length, 1) * 1%));
  height: 1px;
}

.slider-button-wrapper {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  margin-left: calc(50% - 50vw);
  width: 100vw;
}

.slider-button {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.2;
  z-index: 1;
  transition: opacity 0.25s ease-in;

  @media (width >= 1000px) {
    display: flex;
  }
}

[data-has-custom-theme] .slider-button {
  --cosmos-button-background: var(--theming-button-background);
  --cosmos-button-background-hover: var(--theming-button-background-hover);
  --cosmos-button-color: var(--theming-button-color);
  --cosmos-button-color-hover: var(--theming-button-color-hover);
  --cosmos-button-border-width: var(--theming-button-border-width);
  --cosmos-button-border-color: var(--theming-button-border-color);
  --cosmos-button-border-color-hover: var(--theming-button-border-color-hover);
}

/* Linting forces the placement here */
.carousel:is(:hover, :focus-within) .slider-button {
  opacity: 1;
}

.slider-button--next {
  inset-inline-end: 12px;

  @media (width >= 1000px) {
    inset-inline-end: 32px;
  }
}

.slider-button--prev {
  inset-inline-start: 12px;

  @media (width >= 1000px) {
    inset-inline-start: 32px;
  }
}

html[dir="rtl"] .slider-button--next,
html[dir="rtl"] .slider-button--prev {
  transform: scaleX(-1) translateY(-50%); /* Flipped for RTL */
}

.figure {
  /* blank */
}

.image {
  width: 100%;
}

.caption {
  display: flex;
  row-gap: var(--spacing-x-tight);
  flex-direction: column;
  margin-top: var(--spacing-normal);
}
