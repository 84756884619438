.container {
  /* Dev default as part of margin refactor */
  margin-block: var(--spacing-bottom);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-sm);

  @media (width >= 1000px) {
    max-width: var(--max-width-base);
  }
}

@media (width >= 1000px) {
  .main {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }
}

.image {
  display: none;
  position: relative;

  @media (width >= 1000px) {
    display: block;
    grid-column: 2 / 4;
    margin-inline: auto;
    margin-bottom: 40px;
    width: 210px;
  }
}

.dietary-mark-vegetarian {
  --vegetarian-mark-inset-block-end: 3px;
  --vegetarian-mark-inset-inline-end: calc(
    0px - calc(var(--vegetarian-mark-width) + 18px)
  );
  --vegetarian-mark-width: 24px;
}

.can-wrapper {
  position: relative; /* for dietary marks */
}

@media (width >= 1000px) {
  .can {
    display: block;
    width: 100%;
  }
}

.ingredients {
  @media (width >= 650px) {
    grid-column-start: 4;
    grid-column-end: 9;
    padding: 0;
    text-align: initial;
  }

  @media (width >= 1000px) {
    grid-column-start: 6;
    grid-column-end: 12;
  }
}

.title {
  margin-bottom: 40px;
  color: var(--page-theme-contrast-color, var(--color-text-heading));
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-36px);
  line-height: 1.1;
  font-family: var(--font-family-heading);
  text-align: center;

  @media (width >= 650px) {
    margin-inline: auto;
    max-width: 500px;
    font-size: var(--font-size-40px);
    line-height: 1.1;
  }

  @media (width >= 1000px) {
    margin-inline: initial;
    margin-bottom: 70px;
    padding-right: 100px;
    font-size: var(--font-size-48px);
    line-height: 1.17;
    text-align: initial;
  }
}

@media (width >= 1000px) {
  html[dir="rtl"] .title {
    padding-right: unset; /* Layout currently isnt flipped in RTL, so this is correct */
  }
}

.button-wrapper {
  display: flex;
  column-gap: var(--button-wrapper-column-gap);
  row-gap: var(--button-wrapper-row-gap);
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-top: var(--button-wrapper-block-start);

  @media (width >= 650px) {
    flex-direction: row;
  }
}

.button:not([kind="link"]) {
  --cosmos-button-min-width: var(--button-min-width);
}

[data-has-custom-theme] .button[kind="primary"] {
  --cosmos-button-background: var(--theming-button-background);
  --cosmos-button-background-hover: var(--theming-button-background-hover);
  --cosmos-button-color: var(--theming-button-color);
  --cosmos-button-color-hover: var(--theming-button-color-hover);
  --cosmos-button-border-width: var(--theming-button-border-width);
  --cosmos-button-border-color: var(--theming-button-border-color);
  --cosmos-button-border-color-hover: var(--theming-button-border-color-hover);
}

[data-has-custom-theme] .button[kind="link"] {
  --cosmos-button-color: var(--theming-link-button-color);
  --cosmos-button-color-hover: var(--theming-link-button-color-hover);
  --cosmos-button-border-color: var(--theming-link-button-border-color);
  --cosmos-button-border-color-hover: var(
    --theming-link-button-border-color-hover
  );
}

html[dir="rtl"] .button-icon {
  transform: scaleX(-1);
}
