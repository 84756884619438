.container {
  margin-top: 152px;
  margin-bottom: 28px;
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);
  text-align: center;

  @media (width >= 650px) {
    margin-top: 212px;
    margin-bottom: 94px;
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.content {
  position: relative; /* dietary marks */
  padding-bottom: var(--spacing-sides);
  width: 100%;
}

.container--has-teaser,
.container--has-teaser .content {
  padding-bottom: 0;
}

.dietary-mark-high-sugar {
  --high-sugar-mark-inset-block-start: 58px;
  --high-sugar-mark-inset-inline-end: 20px;
  --high-sugar-mark-width: 45px;

  @media (width >= 650px) {
    --high-sugar-mark-inset-block-start: 100px;
    --high-sugar-mark-width: 55px;
  }
}

.kicker {
  display: inline-block;
  position: relative;
  z-index: 2;
  margin-bottom: 8px;
  color: var(--page-theme-contrast-color, var(--color-text));
  font-weight: 700;
  font-size: var(--font-size-18px);
  line-height: 2;
  text-transform: uppercase;
}

.title {
  margin-inline: auto;
  margin-bottom: 24px;
  max-width: 350px;
  color: var(--page-theme-contrast-color, var(--color-text-heading));
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-44px);
  line-height: 1.1;
  font-family: var(--font-family-heading);

  @media (width >= 650px) {
    max-width: 550px;
    font-size: var(--font-size-72px);
    line-height: 1.05;
  }

  @media (width >= 1000px) {
    margin-bottom: 40px;
    max-width: 790px;
    font-size: var(--font-size-96px);
    line-height: 0.9;
  }
}

.text {
  margin-inline: auto;
  margin-bottom: 40px;
  max-width: 385px;
  color: var(--page-theme-contrast-color, var(--color-text));
  line-height: 1.5;

  @media (width >= 650px) {
    max-width: 485px;
  }

  @media (width >= 1000px) {
    margin-bottom: 70px;
    max-width: 590px;
    font-size: var(--font-size-20px);
    line-height: 1.4;
  }
}

.video-container {
  position: relative;
  margin-top: 100px;
  margin-bottom: 40px;

  @media (width >= 650px) {
    margin-top: 0;
  }

  @media (width >= 1000px) {
    margin-bottom: 70px;
  }
}

.video-container::before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 0) 15%,
    rgba(255, 255, 255, 0) 85%,
    rgba(248, 248, 248, 1) 100%
  );
  background-repeat: no-repeat;
  background-blend-mode: normal;
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: "";
}

.video-text {
  margin-bottom: 0;

  @media (width >= 1000px) {
    font-size: var(--font-size-24px);
  }
}
