.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-inline: var(--spacing-sides);

  @media (width >= 768px) {
    padding-top: 60px;
  }

  @media (width >= 1000px) {
    padding-top: 100px;
  }
}

.container--silver-bg {
  background: var(--color-bg-shade);
}

.inner {
  margin-inline: auto;
  padding-bottom: 40px;
  max-width: var(--max-width-base);

  @media (width >= 768px) {
    padding-bottom: 60px;
  }

  @media (width >= 1000px) {
    padding-bottom: 100px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (width >= 1000px) {
    display: grid;
    grid-template-rows: repeat(3, auto);
  }
}

.video-wrapper {
  position: relative;
  margin-bottom: 10px;

  @media (width >= 1000px) {
    grid-row: 2 / 3;
    grid-column: 1 / -1;
  }
}

.video-wrapper--gradient-white::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    to top,
    var(--color-surface-solid-light-lighter) 0,
    rgba(255, 255, 255, 0) 20px
  );
  background-repeat: no-repeat;
  background-blend-mode: normal;
  width: 100%;
  height: 100%;
  content: "";
}

@media (width >= 1000px) {
  .video-wrapper--gradient-white::after {
    background: linear-gradient(
        to top,
        var(--color-surface-solid-light-lighter) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to bottom,
        var(--color-surface-solid-light-lighter) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to left,
        var(--color-surface-solid-light-lighter) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to right,
        var(--color-surface-solid-light-lighter) 0%,
        rgba(255, 255, 255, 0) 100px
      );
  }
}

.video-wrapper--gradient-grey::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    to top,
    var(--color-bg-shade) 0,
    rgba(255, 255, 255, 0) 20px
  );
  background-repeat: no-repeat;
  background-blend-mode: normal;
  width: 100%;
  height: 100%;
  content: "";
}

@media (width >= 1000px) {
  .video-wrapper--gradient-grey::after {
    background: linear-gradient(
        to top,
        var(--color-bg-shade) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to bottom,
        var(--color-bg-shade) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to left,
        var(--color-bg-shade) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to right,
        var(--color-bg-shade) 0,
        rgba(255, 255, 255, 0) 100px
      );
  }
}

.video {
  mix-blend-mode: multiply;
  width: 100%;
  height: auto;
}

.facts-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width >= 1000px) {
    position: relative;
    grid-row: 2 / -1;
    grid-column: 1 / -1;
    z-index: 2;
    margin-top: 30%;
  }
}

@media (width >= 1000px) {
  .facts-wrapper--no-asset {
    margin-top: 0;
  }
}

.title {
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 450px;
  color: var(--color-text-heading);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-28px);
  line-height: 1.3;
  font-family: var(--font-family-heading);
  text-align: center;

  @media (width >= 1000px) {
    margin-top: 25%;
    max-width: 700px;
    font-size: var(--font-size-64px);
    line-height: 1.13;
  }
}

.container:not(.container--silver-bg) .title {
  color: var(--page-theme-contrast-color, var(--color-text-heading));
}

@media (width >= 650px) {
  .title {
    font-size: var(--font-size-40px);
  }
}

@media (width >= 1000px) {
  .title--no-asset {
    margin-top: 0;
  }
}

.buttons {
  display: flex;
  column-gap: 20px;
  row-gap: 15px;
  flex-direction: column;
  align-items: center;

  @media (width >= 650px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.button {
  --cosmos-button-min-width: 222px;
}
