.container {
  margin-top: var(--spacing-sides);
  margin-bottom: var(--spacing-bottom);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);
}

/**
 * Reduces spacing below when the next block is a [Animated]ProductTeaserCard
 */
:is(
    [data-next-type="BlocksAnimatedProductTeaserCard"],
    [data-next-type="BlocksProductTeaserCard"]
  )
  .container {
  margin-bottom: var(--spacing-sides);
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-sm);

  @media (width >= 1000px) {
    max-width: var(--max-width-base);
  }
}

.card {
  display: flex;
  position: relative;
  flex-direction: column;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08);
  border-radius: var(--radius-large);
  background-color: var(--color-surface-solid-light-lighter);
  overflow: hidden;

  @media (width >= 1000px) {
    flex-direction: row;
    border-radius: var(--radius-x-large);
  }
}

/* TODO: this should be refactored so the Anchor doesn't wrap the element */
a.card {
  text-decoration: none;
}

.card--has-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0) 49%,
    rgba(0, 0, 0, 0.25)
  );
  background-blend-mode: overlay;
}

.background-image {
  position: absolute;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 50%;
  object-fit: cover;
  object-position: center bottom;

  @media (width >= 1000px) {
    width: 150%;
    height: 100%;
  }
}

.background-image--animating {
  opacity: 1;
  transition: opacity 1.4s linear;
}

.background-image-gradient {
  position: absolute;
  width: 100%;
  height: 100%;

  @media (width >= 1000px) {
    width: 150%;
    height: 100%;
  }
}

.button-wrapper {
  /* blank */
}

.button:not([kind="link"]) {
  --cosmos-button-min-width: var(--button-min-width);
}

.card:hover {
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.1);
}

.kicker-wrapper {
  display: block;
  margin-bottom: 10px;

  @media (width >= 650px) {
    margin-bottom: 20px;
  }

  @media (width >= 1000px) {
    margin-inline-end: 12px; /* this probably isn't really needed as kickers are usually short... */
  }
}

.card--has-gradient .kicker-wrapper,
.card--has-background .kicker-wrapper {
  --kicker-color: var(--color-text-light);
}

.text-content {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  padding-top: 40px;
  padding-inline: 24px;
  text-align: center;

  @media (width >= 650px) {
    padding-top: 60px;
  }

  @media (width >= 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-inline-start: 80px;
    padding-inline-end: initial;
    padding-block: 80px;
    text-align: initial;
  }
}

.title {
  display: block;
  margin-bottom: 12px;
  color: var(--color-text-heading);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-24px);
  line-height: 1.4;
  font-family: var(--font-family-heading);

  @media (width >= 650px) {
    margin-bottom: 20px;
    font-size: var(--font-size-36px);
    line-height: 1.22;
  }

  @media (width >= 1000px) {
    font-size: var(--font-size-72px);
  }
}

.text {
  margin-bottom: 20px;
  max-width: 428px;
  color: var(--color-text-subtle);
  line-height: 1.7;

  @media (width >= 650px) {
    display: block;
    margin-inline: auto;
    padding-bottom: 30px;
    font-size: var(--font-size-16px);
  }

  @media (width >= 1000px) {
    display: block;
    margin: 0;
    padding-bottom: 74px;
    max-width: none;
    font-size: var(--font-size-21px);
    line-height: 1.7;
  }
}

.text--has-disclaimer {
  margin-bottom: 0;

  @media (width >= 650px) {
    padding-bottom: 0;
  }

  @media (width >= 1000px) {
    padding-bottom: 0;
  }
}

.disclaimer {
  display: block;
  margin-bottom: 20px;
  color: var(--color-text-subtle);
  font-style: italic;
  font-size: var(--font-size-12px);
  line-height: 1.5;

  @media (width >= 650px) {
    margin-bottom: 0;
    padding-bottom: 30px; /* TODO: investigate why we can use margin here? */
  }

  @media (width >= 1000px) {
    padding-bottom: 74px;
  }
}

.disclaimer--centered {
  text-align: center;
}

.card--has-background .title,
.card--has-background .text,
.card--has-background .disclaimer,
.card--has-gradient .title,
.card--has-gradient .text,
.card--has-gradient .disclaimer {
  color: var(--color-text-light);
}

.image-wrapper {
  display: flex;
  position: relative; /* for dietary marks */
  align-items: flex-end;
  margin-inline: auto;
  max-width: 300px;

  @media (width >= 650px) {
    display: block;
  }

  @media (width >= 1000px) {
    display: flex;
    align-items: flex-end;
    margin-inline: initial;
    width: 50%;
    max-width: unset;
  }
}

.dietary-mark-vegetarian {
  --vegetarian-mark-inset-block-end: 18px;
  --vegetarian-mark-inset-inline-end: 18px;
  --vegetarian-mark-width: 16px;

  @media (width >= 650px) {
    --vegetarian-mark-width: 24px;
  }
}

.end-image {
  display: block;
  opacity: 0;
  transition: opacity 1.4s linear;
  width: 100%;
}

.end-image--animating {
  opacity: 1;
  transition: opacity 1.4s linear;
}

.start-image {
  position: absolute;
  bottom: 0;
  opacity: 1;
  width: 100%;
}

.start-image--animating {
  opacity: 0;
  transition: opacity 1.4s linear;
}

.logo {
  display: block;
  width: 100px;

  @media (width >= 350px) {
    width: 130px;
  }

  @media (width >= 650px) {
    margin-inline: auto;
    margin-bottom: 20px;
    width: 200px;
  }

  @media (width >= 1000px) {
    margin-inline: initial;
    margin-bottom: 20px;
    width: 300px;
  }
}
