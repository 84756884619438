/* Hack - for when QA stage is on a qa article page, 
 * This component can be on a white of grey background, currenly our background wrapper doesn't support this.
 * We add some flex to non-collapse the margin and fake a grey background  */

/* Previous CSS code removed, but it kept it in for documentation reasons,
   I have feeling we will revisit this at some point... */

/*
[data-next-type="BlocksQaArticle"]:has(.container) {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-shade);
} 
*/

/* On article single entry pages we toggle the search results */
.container {
  --_show-results-display: none;

  &[data-show-results="true"] {
    --_show-results-display: block;

    margin-bottom: var(--spacing-bottom);
    background-color: var(--color-bg-shade);

    @media (width >= 650px) {
      margin-bottom: calc(var(--spacing-bottom) - 20px);
    }

    @media (width >= 1000px) {
      margin-bottom: calc(var(--spacing-bottom) + 50px);
    }
  }
}

/* This is used only on FAQ detail pages, it's all hacks */
[data-faq-detail="true"]:has(.container) {
  background-color: var(--color-bg-shade);

  @media (width >= 650px) {
    margin-bottom: calc(-1 * var(--spacing-top));
  }
}

/* This is used only on FAQ detail pages, it's all hacks */
[data-faq-detail="true"] .container[data-show-results="true"] {
  margin-bottom: var(--spacing-bottom);
}

@media (width >= 650px) {
  .filter-container {
    padding-inline: 40px;
  }
}

.filter {
  display: flex;
  position: relative;
  column-gap: 24px;
  row-gap: 8px;
  flex-direction: column;
  z-index: 2;
  box-shadow: 0 16px 32px 0 rgba(0, 30, 60, 0.12);
  background-color: var(--color-surface-solid-light-lighter);
  padding: 24px;
  max-width: 880px;
  color: var(--color-text);

  @media (width >= 650px) {
    display: flex;
    flex-direction: row;
    margin-top: -51px;
    margin-inline: auto;
    border-radius: var(--radius-large);
  }
}

.search-field {
  position: relative;

  @media (width >= 650px) {
    width: 50%;
  }
}

.search-field-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-inline-start: 17px; /* RTL support */
  width: 22px;
  height: 22px;
  color: var(--color-primary);
}

.search-field-input {
  -webkit-appearance: none;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-surface-glass-dark-10);
  border-radius: var(--radius-medium);
  background: var(--color-surface-solid-light-lighter);
  padding-inline-start: 52px; /* RTL support */
  padding-inline-end: 24px; /* RTL support */
  width: 100%;
  height: 50px;
  color: var(--color-text);
  font-size: var(--font-size-18px);
  line-height: 2;
}

.search-field-input::placeholder {
  color: var(--color-text-subtle);
}

.results-container {
  display: var(--_show-results-display, block);
  padding-inline: 36px;

  @media (width >= 650px) {
    padding-inline: var(--spacing-sides);
  }
}

.results-controls {
  display: flex;
  align-items: center;
  margin-inline: auto;
  max-width: 792px;
}

.results-controls + * {
  margin-top: 16px;

  @media (width >= 650px) {
    margin-top: 50px;
  }
}

.results-count {
  color: var(--color-text);
  font-size: var(--font-size-20px);

  @media (width >= 650px) {
    font-size: var(--font-size-38px);
  }
}

.results-count--no-results {
  color: var(--color-text-subtle);
}

.reset {
  --cosmos-button-color: var(--color-text-subtle);
  --cosmos-button-color-hover: var(--color-text-subtle);
  --cosmos-button-font-size: var(--font-size-14px);
  --cosmos-button-font-weight: 400;
  --cosmos-button-font-family: var(
    --ff-bull-text
  ); /* hack: default cosmos button does not have font weights other than 500 */

  margin-inline-start: auto; /* RTL support */
  color: var(--color-text-subtle);

  @media (width >= 650px) {
    --cosmos-button-font-size: var(--font-size-24px);
  }
}

.reset-icon {
  display: none;

  @media (width >= 650px) {
    --cosmos-icon-size: 20px;

    display: block;
    margin-inline-end: 8px; /* RTL support */
  }
}

.card {
  display: block;
  transition: box-shadow 0.3s ease-in-out;
  margin-inline: auto;
  margin-bottom: 10px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08);
  border-radius: var(--radius-large);
  background-color: var(--color-surface-solid-light-lighter);
  padding-top: 20px;
  padding-right: 28px; /* why is this 4px bigger? */
  padding-bottom: 26px;
  padding-left: 24px;
  max-width: 792px;
  text-decoration: none;

  @media (width >= 650px) {
    margin-bottom: 25px;
    padding-block: 32px;
    padding-right: 55px;
    padding-left: 36px;
  }
}

.card:hover {
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.12);
}

.card:last-child {
  margin-bottom: 0;
}

.card--category {
  display: flex;
  align-items: stretch;
  padding: 0;
}

.card-header {
  display: flex;
  margin-bottom: 10px;
  font-size: var(--font-size-14px);

  @media (width >= 650px) {
    margin-bottom: 20px;
    font-size: var(--font-size-20px);
    line-height: 1.44;
  }
}

.card-button {
  --cosmos-button-min-width: var(--button-min-width);

  display: none;

  @media (width >= 650px) {
    display: inline-block;
  }
}

.ingredient-title {
  position: relative;
  z-index: 1;
  margin-bottom: 16px;
  color: var(--color-primary);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-21px);
  line-height: 1.14;
  font-family: var(--font-family-heading);

  @media (width >= 650px) {
    margin-bottom: 32px;
    font-size: var(--font-size-48px);
    line-height: 1.17;
  }
}

.ingredient-content {
  & p {
    margin-bottom: 16px;
    font-size: var(--font-size-18px);
    line-height: 1.33;
    letter-spacing: 0.4px;

    @media (width >= 650px) {
      margin-bottom: 24px;
      letter-spacing: normal;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & b {
      font-weight: 500;

      @media (width >= 650px) {
        font-size: var(--font-size-24px);
        line-height: 1.5;
      }
    }
  }
}

.read-more-container {
  position: relative;
}

.read-more-container::after {
  position: absolute;
  bottom: 22px;
  left: 0;
  z-index: 0;
  background-image: linear-gradient(
    to top,
    var(--color-surface-solid-light-lighter),
    rgba(255, 255, 255, 0)
  );
  width: 100%;
  height: 200px;
  content: "";
}

@media (width >= 650px) {
  .read-more-container::after {
    height: 100px;
  }
}

.read-more {
  --cosmos-button-color: var(--color-primary);
  --cosmos-button-color-hover: var(--color-primary-darker);
  --cosmos-button-border-color-hover: color-mix(
    in srgb,
    var(--color-primary-darker),
    transparent 80%
  );
  --cosmos-font-size: var(--font-size-16px);

  position: relative;
  z-index: 1;

  @media (width >= 650px) {
    --cosmos-font-size: var(--font-size-18px);
  }
}

html[dir="rtl"] .read-more-icon {
  transform: scaleX(-1); /* icon flipped for RTL */
}

.section-title {
  margin-inline-end: 12px; /* RTL support */
  color: var(--color-text-heading);
  font-weight: var(--font-weight-heading);
  text-transform: uppercase;
}

.section-title--grey {
  color: var(--color-text-subtle);
  font-weight: normal;
  text-transform: none;
}

.category-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 20px;
  padding-right: 28px;
  padding-bottom: 26px;
  padding-left: 24px;
  width: 50%;

  @media (width >= 650px) {
    padding-top: 32px;
    padding-bottom: 44px;
    padding-left: 36px;
  }
}

.category-title {
  color: var(--color-text-heading);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-20px);
  line-height: 1.4;
  font-family: var(--font-family-heading);

  @media (width >= 650px) {
    margin-bottom: 40px;
    font-size: var(--font-size-48px);
    line-height: 1.17;
  }
}

.category-image-wrapper {
  display: flex;
  position: relative;
  align-items: flex-end;
  width: 50%;
}

.dietary-mark-vegetarian {
  --vegetarian-mark-inset-block-end: 11px;
  --vegetarian-mark-inset-inline-end: 11px;
  --vegetarian-mark-width: 16px;

  @media (width >= 650px) {
    --vegetarian-mark-inset-block-end: 18px;
    --vegetarian-mark-inset-inline-end: 18px;
    --vegetarian-mark-width: 24px;
  }
}

/* Not currently used */

/* .dietary-mark-high-sugar {
  --high-sugar-mark-inset-block-end: 65%;
  --high-sugar-mark-inset-inline-end: 11px;
  --high-sugar-mark-width: 24px;

  @media (width >= 650px) {
    --high-sugar-mark-inset-block-end: 65%;
    --high-sugar-mark-inset-inline-end: 18px;
    --high-sugar-mark-width: 32px;
  }

  @media (width >= 1000px) {
    --high-sugar-mark-inset-block-end: 280px;
    --high-sugar-mark-width: 55px;
  }
} */

.category-image {
  display: block;
  width: 100%;
}

.result-title {
  color: var(--color-text);
  font-size: var(--font-size-20px);
  line-height: 1.4;

  @media (width >= 650px) {
    font-size: var(--font-size-30px);
    line-height: 1.47;
  }
}
