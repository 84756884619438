.container {
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
}

.filter {
  position: relative;
  z-index: 2;
  box-shadow: 0 16px 32px 0 rgba(0, 30, 60, 0.12);
  background-color: var(--color-surface-solid-light-lighter);
  padding: 24px;
  max-width: 809px;

  @media (width >= 650px) {
    margin-top: -51px;
    margin-inline: auto;
  }

  @media (width >= 808px) {
    border-radius: var(--radius-large);
  }
}

.highlights {
  position: relative; /* for dietary marks */
}

.dietary-mark-high-sugar {
  --high-sugar-mark-inset-block-start: 20px;
  --high-sugar-mark-inset-inline-end: 20px;
  --high-sugar-mark-width: 45px;

  @media (width >= 650px) {
    --high-sugar-mark-inset-block-start: -30px;
    --high-sugar-mark-width: 55px;
  }
}
