.gallery {
  /* settings */

  --_column-count: 3;
  --_gap: 20px;
  --_min-column-size: calc(
    (var(--max-width-base) / var(--_column-count)) - var(--_gap)
  );

  /* calculations */
  --_column-size: calc((100% / var(--_column-count)) - var(--_gap));

  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(max(var(--_min-column-size), var(--_column-size)), 1fr)
  );
  column-gap: var(--_gap);
  row-gap: var(--_gap);
}

.image {
  width: 100%;
  height: auto;
}

.caption {
  display: flex;
  row-gap: var(--spacing-x-tight);
  flex-direction: column;
  margin-top: var(--spacing-normal);
}
