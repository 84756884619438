.container {
  position: relative; /* for dietary marks */
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
}

.dietary-mark-high-sugar {
  --high-sugar-mark-inset-block-start: 58px;
  --high-sugar-mark-inset-inline-end: 20px;
  --high-sugar-mark-width: 45px;

  @media (width >= 650px) {
    --high-sugar-mark-inset-block-start: 100px;
    --high-sugar-mark-width: 55px;
  }
}

.filter {
  position: relative;
  z-index: 2;
  box-shadow: 0 16px 32px 0 rgba(0, 30, 60, 0.12);
  background-color: var(--color-surface-solid-light-lighter);
  padding: 24px;
  max-width: 809px;

  @media (width >= 650px) {
    margin-top: -51px;
    margin-inline: auto;
  }

  @media (width >= 808px) {
    border-radius: var(--radius-large);
  }
}

.qa-section {
  position: relative;
  border-bottom-right-radius: var(--radius-medium);
  border-bottom-left-radius: var(--radius-medium);
  padding: 16px;
  color: var(--color-surface-solid-light-lighter);
  font-weight: bold;
}

.qa-section::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom-right-radius: var(--radius-medium);
  border-bottom-left-radius: var(--radius-medium);
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.26),
    rgba(0, 0, 0, 0.8)
  );
  content: "";
}

.qa-section-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qa-section-content {
  position: relative;
  z-index: 1;
}

.qa-section-title {
  margin-bottom: 16px;
  font-size: var(--font-size-16px);
}

.qa-section-description {
  margin-bottom: 16px;
  max-width: 211px;
  font-size: var(--font-size-18px);
}

.qa-section-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--color-surface-solid-light-lighter);
  border-radius: var(--radius-medium);
  width: 170px;
  height: 50px;
  color: var(--color-text-light);
  line-height: 1;
  text-decoration: none;

  &:hover {
    border-color: var(--color-primary-darker);
    background-color: var(--color-primary-darker);
    color: var(--color-text-light);
  }
}
