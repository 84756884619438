/* Do not place overflow: hidden on this container, it interferes with dropdowns  */

.container {
  transition: background-color 0.25s ease;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-inline: var(--spacing-sides);
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.content {
  position: relative;

  @media (width >= 1000px) {
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
    min-height: 865px;
  }
}

.dietary-mark-high-sugar {
  --high-sugar-mark-inset-block-start: 58px;
  --high-sugar-mark-inset-inline-end: 20px;
  --high-sugar-mark-width: 45px;

  @media (width >= 650px) {
    --high-sugar-mark-inset-block-start: 100px;
    --high-sugar-mark-width: 55px;
  }
}

.content--with-background {
  height: auto;
}

.can {
  margin-inline: auto;

  @media (width >= 650px) {
    grid-column-start: 1;
    grid-column-end: 4;
    order: 1;
  }

  @media (width >= 1000px) {
    grid-column-start: 2;
    grid-column-end: 4;
    margin-bottom: 31px;
  }
}

.dietary-mark-vegetarian {
  --vegetarian-mark-inset-block-end: 93px;
  --vegetarian-mark-inset-inline-start: 50%;
  --vegetarian-mark-width: 24px;
  --vegetarian-mark-transform: translateX(calc(-50% + 115px));

  @media (width >= 650px) {
    --vegetarian-mark-transform: translateX(calc(-50% + 120px));
  }

  @media (width >= 1000px) {
    --vegetarian-mark-inset-inline-start: initial;
    --vegetarian-mark-inset-inline-end: -42px;
    --vegetarian-mark-transform: initial;
  }
}

.can-image-wrapper {
  position: relative;
}

.can-image {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 175px;

  @media (width >= 650px) {
    width: 182px;
  }

  @media (width >= 1000px) {
    width: 210px;
  }
}

.can-image--stamp {
  left: -10px;
  width: 230px;

  @media (width >= 650px) {
    width: 235px;
  }

  @media (width >= 1000px) {
    left: -20px;
    width: 270px;
  }
}

.text {
  margin-bottom: 32px;

  @media (width >= 650px) {
    grid-column: 4 / -1;
    order: 2;
    margin-bottom: 50px;
  }

  @media (width >= 1000px) {
    grid-column: 6 / -1;
    padding-top: 50px;
  }
}

.text--centered {
  grid-column: 2 / -2;
  order: 0;
  text-align: center;
}

.kicker-wrapper {
  display: block;
  margin-bottom: 16px;
  margin-inline: auto;
  color: var(--page-theme-contrast-color, var(--color-text));
  text-align: center;

  @media (width >= 1000px) {
    margin-bottom: 32px;
    margin-inline: initial;
    text-align: initial;
  }
}

[data-has-custom-theme] .kicker {
  --kicker-border-color: var(--theming-kicker-border-color);
}

.title {
  margin-inline: auto;
  margin-bottom: 16px;
  max-width: 320px;
  color: var(--page-theme-contrast-color, var(--color-text-heading));
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-37px); /* Cosmos Title x-large */
  line-height: 1.07;
  font-family: var(--font-family-heading);
  text-align: center;

  @media (width >= 650px) {
    margin-bottom: 30px;
    max-width: 400px;
    font-size: var(--font-size-56px);
  }

  @media (width >= 1000px) {
    margin-bottom: 45px;
    max-width: none;
    font-size: var(--font-size-72px);
    line-height: 1;
    text-align: initial;
  }
}

@media (width >= 1000px) {
  .title--centered {
    text-align: center;
  }
}

.description {
  margin-inline: auto;
  max-width: 485px;
  color: var(--page-theme-contrast-color, var(--color-text-subtle));
  font-size: var(--font-size-14px);
  line-height: var(--type-x-small-line-height);
  text-align: center;

  @media (width >= 650px) {
    font-size: var(--font-size-16px);
    line-height: 1.5;
  }

  @media (width >= 1000px) {
    margin-inline: initial;
    margin-bottom: 45px;
    font-size: var(--font-size-24px);
    text-align: initial;
  }
}

@media (width >= 1000px) {
  .description--centered {
    margin-inline: auto;
    margin-bottom: 0;
    text-align: center;
  }

  .description--has-disclaimer + * {
    margin-top: 0;
  }
}

.disclaimer {
  display: none;

  @media (width >= 650px) {
    display: block;
    margin-bottom: 20px;
    color: var(--page-theme-content-color, var(--color-text-subtle));
    font-style: italic;
    font-size: var(--font-size-12px);
    line-height: 1.5;
  }

  @media (width >= 1000px) {
    font-size: var(--font-size-16px);
  }
}

@media (width >= 650px) {
  .disclaimer--centered {
    text-align: center;
  }
}

.button-wrapper {
  display: flex;
  column-gap: var(--button-wrapper-column-gap);
  row-gap: var(--button-wrapper-row-gap);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: var(--button-wrapper-block-start);

  @media (width >= 1000px) {
    justify-content: flex-start;
  }
}

.button:not([kind="link"]) {
  --cosmos-button-min-width: var(--button-min-width);
}

[data-has-custom-theme] .button[kind="primary"] {
  --cosmos-button-background: var(--theming-button-background);
  --cosmos-button-background-hover: var(--theming-button-background-hover);
  --cosmos-button-color: var(--theming-button-color);
  --cosmos-button-color-hover: var(--theming-button-color-hover);
  --cosmos-button-border-width: var(--theming-button-border-width);
  --cosmos-button-border-color: var(--theming-button-border-color);
  --cosmos-button-border-color-hover: var(--theming-button-border-color-hover);
}

html[dir="rtl"] .button-icon {
  transform: scaleX(-1); /* Chevron icon flipped for RTL */
}

.background {
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: -220px;
  margin-left: calc(50% - 50vw); /* full bleed */
  width: 100vw; /* full bleed */
  overflow: hidden; /* stop background image overflowing */

  @media (width >= 1000px) {
    margin-top: -120px;
  }
}

.background-image {
  display: block;
  transform: scale(0.7) translateY(22%);
  opacity: 0;
  animation: background--fade-in 2s 1s ease-in forwards;

  @media (width >= 1000px) {
    transform: none;
    width: auto;
  }
}

.background-can {
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  overflow: hidden; /* overflowing Can is cropped at the bottom */
}

.background-can-image {
  display: block;
  position: relative;
  transform: translateY(102px);
  width: 160px;

  @media (width >= 1000px) {
    transform: translateY(135px);
    width: 210px;
  }
}

@keyframes background--fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
