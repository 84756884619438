.dropdown {
  position: relative;
  z-index: 1;
  height: 50px;

  @media (width >= 650px) {
    position: relative;
    width: 50%;
    height: 50px;
  }
}

.dropdown-list {
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-surface-glass-dark-10);
  border-radius: var(--radius-medium);
  background: var(--color-surface-solid-light-lighter);
  width: 100%;
  max-height: 50px;
  overflow: hidden;
}

.dropdown-list--expanded {
  max-height: 280px;
  overflow-y: scroll;
}

.dropdown-label {
  position: relative;
  cursor: pointer;
  padding-inline-start: 50px; /* rtl support */
  padding-inline-end: 16px; /* rtl support */
  padding-block: 16px;
  height: 50px;
  color: var(--color-text-subtle); /* is this ever not active? */
  font-size: var(--font-size-18px);
  line-height: 1;
}

.dropdown-label--active-category {
  color: var(--color-primary);
  font-weight: 700;
  font-size: var(--font-size-16px);
}

.dropdown-label-tag-icon {
  --cosmos-icon-color: var(--color-primary);

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-inline-start: -36px; /* RTL support, goes inside padding */
  width: 22px;
  height: 22px;
}

.dropdown-label-chevron-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  transition: transform 0.2s ease;
  margin-block: auto;
  inset-inline-end: 11px; /* RTL support */
  width: 23px;
  height: 23px;
  color: var(--color-primary);
}

.dropdown-list--expanded .dropdown-label-chevron-icon {
  transform: rotateX(180deg);
}

.dropdown-option {
  display: flex;
  cursor: pointer;
  padding: 16px;
  height: 50px;
  color: var(--color-text);
  font-weight: 700;
  user-select: none;
  text-decoration: none;
}

.dropdown-option:hover,
.dropdown-option[aria-selected="true"] {
  outline: none;
  color: var(--color-primary);
}
